<template>
  <div>
    <h1
      class="text-2xl leading-8 font-semibold mb-4 flex place-content-between mb-5"
    >
      {{ $t("checking") }}
      <InputText
        :placeholder="$t('search')"
        v-model="filters.search"
        v-on:keyup.enter="getUserSubmissions"
      >
        <template #leftIcon>
          <SearchIcon class="h-5 text-gray-400" />
        </template>
      </InputText>
    </h1>
    <div class="flex place-content-start gap-4 mb-5">
      <label class="w-1/2">
        <span class="text-sm leading-5 font-medium text-gray-700">{{
          $t("course")
        }}</span>
        <CoursesSelector
          label="name"
          v-model="filters.course"
          :placeholder="$t('select_course')"
          class="bg-white text-sm leading-5 font-medium mt-2"
        />
      </label>
      <!-- <label class="w-[30%]"> -->
      <!-- <span class="text-sm leading-5 font-medium text-gray-700">{{ $t('lesson') }}</span> -->
      <!-- <LessonSelector
          label="name"
          v-model="filters.resource_id"
          multiple
          :course_id="filters.course_id"
          :placeholder="$t('select_lesson')"
          class="bg-white text-sm leading-5 font-medium mt-2"/> -->
      <!-- <TheSelect class="bg-white text-sm leading-5 font-medium mt-2"/> -->
      <!-- </label> -->
      <label class="w-1/2">
        <span class="text-sm leading-5 font-medium text-gray-700">{{
          $t("group")
        }}</span>
        <GroupSelector
          label="name"
          :course_id="filters.course"
          v-model="filters.group_number"
          :placeholder="$t('select_group')"
          class="bg-white text-sm leading-5 font-medium mt-2"
        />
        <!-- <TheSelect class="bg-white text-sm leading-5 font-medium mt-2"/> -->
      </label>
    </div>
    <div class="flex">
      <TheCard
        class="w-3/5 mr-4 relative h-fit min-h-[50vh] max-w-[60%] min-w-[60%] box-border place-content-center"
      >
        <div class="flex flex-row w-full justify-between mb-7">
          <TheButton
            v-for="status in available_statuses"
            :key="status.label + status.amount"
            :bg="status.name === filters.status ? 'secondary' : 'gray'"
            :size="'sm'"
            style="margin: 0px 5px"
            @click="filters.status = status.name"
          >
            {{ $t(status.label, { amount: status.amount }) }}
          </TheButton>
        </div>
        <div class="mb-24">
          <TheUserExamineCard
            v-for="user in user_submissions"
            :avatar="user.avatar"
            :full_name="user.full_name"
            :course_name="user.course_name"
            :submitted_at="user.submitted_at"
            :resource_name="user.resource_name"
            :question_text="user.question_text"
            :id="user.id"
            :key="`$user_submission_${user.id}`"
            class="mb-3 selectedCard"
            @click="setSelectedUser(user)"
          >
          </TheUserExamineCard>
        </div>
        <ThePaginator
          :pagination="pagination"
          @currentPageChange="(page) => (filters.page = page)"
          class="absolute bottom-0 flex place-content-center left-6 right-6"
        />
      </TheCard>
      <div
        class="w-2/5 box-border max-w-[40%] min-w-[40%] avv flex flex-col"
        v-if="selected_user && selected_user.full_name"
        style="height: 150vh; position: sticky; top: 100px"
        :class="{ scrollable: isPointerOver }"
        @mouseover="isPointerOver = true"
        @mouseleave="isPointerOver = false"
      >
        <div>
          <TheCard>
            <div
              class="text-base leading-6 font-medium text-indigo-500 pb-4 break-word"
            >
              {{ selected_user.course_name }}
            </div>
            <div
              class="text-base leading-6 font-semibold text-gray-800 flex align-middle place-items-center pt-4 border-t border-gray-200 break-word"
            >
              <BookOpenIcon class="h-6 mr-2" />
              {{ selected_user.resource_name }}
            </div>
            <div
              v-html="selected_user.question_text"
              class="text-base leading-5 font-normal text-gray-500 break-word"
            />
            <div
              v-if="selected_user.criteria.length > 0"
              class="text-base leading-6 font-semibold text-gray-800 flex align-middle place-items-center pt-4 pb-4 border-b border-gray-200 break-word"
            >
              <BadgeCheckIcon class="h-6 mr-2" />
              {{
                $t("threshold_out_of", {
                  threshold: selected_user.threshold,
                  max_points: calcCriteriaWeight,
                })
              }}
            </div>
          </TheCard>
          <TheCard
            style="
              padding: 0;
              padding-bottom: 24px;
              overflow: hidden;
              min-width: 100% !important;
              max-width: 100% !important;
            "
            :additional_class="'max-w-[100%] min-w-[100%]'"
          >
            <div class="bg-indigo-100 px-5 py-4 flex gap-2">
              <div class="mr-2">
                <UserAvatar class="flex-shrink-0" />
              </div>
              <div class="flex flex-col">
                <span class="text-sm leading-5 font-medium text-gray-900">{{
                  selected_user.full_name
                }}</span>
                <span class="text-sm leading-5 font-normal text-gray-500"
                  >Online</span
                >
              </div>
            </div>
            <div class="mt-4 px-5">
              <div
                class="mb-4 text-xs leading-4 font-medium flex justify-between items-center"
              >
                <div>
                  <span
                    class="mr-2 text-base leading-6 font-semibold text-gray-800"
                  >
                    {{ $t("answer") }}:</span
                  >
                  <Badge
                    bg="primary"
                    v-if="selected_user.status === 'submitted'"
                    >{{ $t("submitted") }}</Badge
                  >
                  <Badge
                    bg="being_checked"
                    v-if="selected_user.status === 'being_checked'"
                    >{{ $t("being_checked") }}</Badge
                  >
                  <Badge
                    bg="has_grades"
                    v-if="selected_user.status === 'has_grades'"
                    >{{ $t("has_grades") }}</Badge
                  >
                  <Badge
                    bg="danger"
                    v-if="selected_user.status === 'being_finalized'"
                    >{{ $t("being_finalized") }}</Badge
                  >
                  <Badge
                    bg="success"
                    v-if="selected_user.status === 'finished'"
                    >{{ $t("accepted") }}</Badge
                  >
                </div>

                <div class="flex gap-3 items-center student__grade">
                  <div class="text-base font-medium">Ваша оценка: </div>
                  <input type="number" v-model="grade" :placeholder="selected_user.instructor_grade?selected_user.instructor_grade:0" />
                </div>
              </div>
              <div class="px-5 py-4 border-[1px] border-gray-200 rounded-xl">
                <div class="flex gap-3">
                  <div class="mr-2">
                    <UserAvatar
                      class="flex-shrink-0"
                      :url="selected_user.avatar"
                    />
                  </div>
                  <div class="flex flex-col w-full">
                    <span class="text-sm leading-5 font-medium text-gray-900">{{
                      selected_user.full_name
                    }}</span>
                    <span
                      class="text-sm leading-5 font-normal text-gray-500 mb-3"
                      >{{ selected_user.submitted_time }}</span
                    >
                    <span
                      class="text-sm leading-5 font-normal text-gray-500 break-all"
                    >
                      {{ selected_user.answer }}
                    </span>
                    <div class="flex flex-col">
                      <FileLink
                        v-for="file in selected_user.submissions"
                        :url="file.file"
                        :name="file.name"
                        :size="file.size"
                        :key="file.file"
                        class="mt-3"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="selected_user.criteria.length > 0" class="mt-3">
                <CriterialGrading
                  :practice="selected_user"
                  :is_point="is_point"
                />
                <div
                  class="flex mt-3 justify-between gap-6"
                  v-if="selected_user.status !== 'finished'"
                >
                  <TheButton
                    v-if="
                      is_point && calcCriteriaPoints < selected_user.threshold
                    "
                    bg="finalize"
                    class="m-1 w-full"
                    @click="changeStatus('being_finalized')"
                  >
                    {{ $t("send_finalized") }}
                    <ReplyIcon class="h-4 ml-2" />
                  </TheButton>
                  <TheButton
                    v-if="
                      is_point && calcCriteriaPoints >= selected_user.threshold
                    "
                    bg="accept"
                    class="m-1 w-full"
                    @click="changeStatus('finished')"
                  >
                    {{ $t("accept") }}
                    <CheckIcon class="h-4 ml-2" />
                  </TheButton>
                </div>
              </div>
              <div v-else class="flex mt-3 justify-between gap-6">
                <TheButton
                  bg="finalize"
                  class="m-1 w-full"
                  @click="changeStatus('being_finalized')"
                >
                  {{ $t("send_finalized") }}
                  <ReplyIcon class="h-4 ml-2" />
                </TheButton>
                <TheButton
                  bg="accept"
                  class="m-1 w-full"
                  @click="changeStatus('finished')"
                >
                  {{ $t("accept") }}
                  <CheckIcon class="h-4 ml-2" />
                </TheButton>
              </div>
              <div class="mt-10">
                <div
                  v-if="show_alert"
                  class="py-3 px-5 mb-4 bg-green-50 text-green-600 text-sm rounded-md flex items-center justify-between"
                  role="alert"
                >
                  <span>{{ $t("send_comment_text") }}</span>
                  <button
                    class="w-4"
                    type="button"
                    data-dismiss="alert"
                    aria-label="Close"
                    @click="show_alert = false"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="{2}"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
                <label class="text-base leading-6 font-semibold text-gray-800"
                  >{{ $t("your_comment") }}:</label
                >
                <textarea
                  v-model="selected_user.instructor_comment"
                  class="w-full border-gray-200 border-[1px] rounded-xl my-3"
                />
                <div
                  v-if="!!selected_user.instructor_file"
                  class="block mb-4 cursor-pointer"
                >
                  <a
                    v-if="typeof selected_user.instructor_file === 'string'"
                    :href="selected_user.instructor_file"
                    target="_blank"
                  >
                    {{ selected_user.instructor_file.split("/").at(-1) }}
                  </a>
                  <div v-else>{{ selected_user.instructor_file.name }}</div>
                </div>
                <div class="flex place-content-between">
                  <input
                    type="file"
                    id="upload-file"
                    @change="handleFileUpload"
                    hidden
                  />
                  <TheButton bg="outline" @click="handleFileAttachClick">
                    <PaperClipIcon class="h-4 mr-2" />
                    {{ $t("attach_file") }}
                  </TheButton>
                  <TheButton @click="commentSubmission" bg="primary">
                    {{ $t("send") }}
                    <PaperAirplaneIcon class="ml-2 h-4 rotate-90" />
                  </TheButton>
                </div>
              </div>
            </div>
          </TheCard>
          <TheCard>
            <div
              class="text-base leading-6 font-medium text-indigo-500 pb-4 break-word"
            >
              {{ $t("submissions_history") }}
            </div>
            <div class="border-t border-gray-200 break-word">
              <AnswerContent
                v-for="attempt in user_attempts"
                :answer="attempt"
                :key="`user_attempt_${attempt.id}`"
              />
            </div>
          </TheCard>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import InputText from "@/components/InputText";
import {
  BadgeCheckIcon,
  BookOpenIcon,
  CheckIcon,
  PaperClipIcon,
  ReplyIcon,
  SearchIcon,
} from "@heroicons/vue/outline";
import { PaperAirplaneIcon } from "@heroicons/vue/solid";
// import TheSelect from "@/components/Selectors/TheSelect";
import TheCard from "@/components/TheCard/TheCard";
// import TheCardHeader from "@/components/TheCard/TheCardHeader.vue";
import TheButton from "@/components/Button/Button";
import ThePaginator from "@/components/ThePaginator/ThePaginator";
import TheUserExamineCard from "@/components/TheUserExamineCard/TheUserExamineCard";
import UserAvatar from "@/components/UserAvatar";
import FileLink from "@/components/Files/FileLink";
import Badge from "@/components/TheBadge/TheBadge";
import moment from "moment";
import CoursesSelector from "@/components/Selectors/CoursesSelector";
import { mapFields } from "vuex-map-fields";
// import LessonSelector from "@/components/Selectors/LessonSelector.vue";
import GroupSelector from "@/components/Selectors/GroupSelector.vue";
import AnswerContent from "@/components/Resources/ResourceBlock/Answers/AnswerContent.vue";
import CriterialGrading from "@/components/CriterialGrading/CriterialGrading.vue";

export default {
  computed: {
    ...mapFields(["courses.courses"]),
    currentLocale() {
      return this.$i18n.locale;
    },
    calcCriteriaPoints() {
      let result;
      if (this.selected_user.criteria_points.length > 1) {
        result = this.selected_user.criteria_points.reduce((a, b) => {
          return parseInt(a) + parseInt(b.point);
        }, 0);
      } else if (this.selected_user.criteria.length == 1) {
        result = this.selected_user.criteria_points[0].point
          ? this.selected_user.criteria_points[0].point
          : 0;
      } else {
        result = 0;
      }
      return result;
    },
    calcCriteriaWeight() {
      let result = 0;
      if (this.selected_user.criteria.length > 1) {
        result = this.selected_user.criteria.reduce((a, b) => {
          return a + b.weight;
        }, 0);
      } else if (this.selected_user.criteria.length == 1) {
        result = this.selected_user.criteria[0].weight;
      } else {
        result = 0;
      }
      return result;
    },
  },
  data: () => ({
    isPointerOver: false,
    show_alert: false,
    available_statuses: [
      { name: "all", label: "all_with_amount", amount: 0 },
      { name: "submitted", label: "not_checked_with_amount", amount: 0 },
      { name: "being_checked", label: "being_checked_with_amount", amount: 0 },
      { name: "has_grades", label: "has_grades_with_amount", amount: 0 },
      {
        name: "being_finalized",
        label: "being_finalized_with_amount",
        amount: 0,
      },
      { name: "finished", label: "finished_with_amount", amount: 0 },
    ],
    filters: {
      status: "all",
      per_page: 10,
    },
    pagination: {
      max_page: 1,
      current_page: 1,
      per_page: 10,
    },
    user_submissions: [
      {
        full_name: "Анастасия Павлова",
        course_name: "Профессия преподаватель английского языка",
        submitted_at: "2022-05-24T03:41:00.288574Z",
      },
      {
        full_name: "Айман Сеитова",
        course_name: "Органическая химия",
        submitted_at: "2022-05-24T03:41:00.288574Z",
      },
      {
        full_name: "Айбек Иманбаев",
        course_name: "Профессия преподаватель английского языка",
        submitted_at: "2022-05-24T03:41:00.288574Z",
      },
      {
        full_name: "Алан Иманбаев",
        course_name: "Органическая химия",
        submitted_at: "2022-05-24T03:41:00.288574Z",
      },
      {
        full_name: "Ирина Брежнева",
        course_name: "Основы генетики",
        submitted_at: "2022-05-24T03:41:00.288574Z",
      },
    ],
    form: {
      file: null,
      user_id: "",
      comment: "",
    },
    selected_user: {},
    user_attempts: [],
    is_point: true,
    offset: 100,
    grade: null,
  }),
  components: {
    Badge,
    FileLink,
    UserAvatar,
    TheUserExamineCard,
    ThePaginator,
    TheButton,
    TheCard,
    // TheCardHeader,
    // TheSelect,
    InputText,
    SearchIcon,
    BookOpenIcon,
    PaperClipIcon,
    PaperAirplaneIcon,
    CheckIcon,
    ReplyIcon,
    CoursesSelector,
    // LessonSelector,
    GroupSelector,
    AnswerContent,
    BadgeCheckIcon,
    CriterialGrading,
  },
  methods: {
    getUserSubmissionsOnCreated() {
      this.getUserSubmissions().then((res) => {
        // Создаем объект с новыми значениями
        const countsMap = new Map(res.counts.map((el) => [el.status_name, el.count]));

        this.available_statuses.forEach((status) => {
          status.amount = countsMap.get(status.name) ?? 0; // Если нет в ответе, ставим 0
        });

        const totalCount = [...countsMap.values()].reduce((sum, count) => sum + count, 0);
        const allStatusObj = this.available_statuses.find((el) => el.name === "all");
        if (allStatusObj) {
          allStatusObj.amount = totalCount;
        }
      });
    },
    handleFileUpload(event) {
      this.selected_user.instructor_file = event.target.files[0];
    },
    handleFileAttachClick() {
      const fileInput = document.querySelector("#upload-file");
      fileInput.click();
    },
    async commentSubmission() {
      const formData = new FormData();
      formData.append("status", this.selected_user.status);
      formData.append(
        "instructor_comment",
        this.selected_user.instructor_comment
      );
      if(this.grade){
        formData.append("instructor_grade", this.grade);
      }
      const instructorFile = this.selected_user.instructor_file;
      !!instructorFile &&
        typeof instructorFile !== "string" &&
        formData.append("instructor_file", instructorFile);

      if (!this.is_point) {
        await this.$api.courses
          .update_submission(this.user_attempts[0].id, formData)
          .then((res) => {
            this.selected_user = res.data;
            this.show_alert = true;
            setTimeout(() => {
              this.isAdded = false;
            }, 5000);
          });
      } else {
        this.selected_user.criteria_points.forEach(function (el) {
          el.point = parseInt(el.point);
        });
        formData.append("criteria_points", this.selected_user.criteria_points);
        await this.$api.courses
          .update_submission(this.user_attempts[0].id, formData)
          .then((res) => {
            this.selected_user = res.data;
            this.show_alert = true;
            setTimeout(() => {
              this.isAdded = false;
            }, 5000);
          });
      }
    },
    async changeStatus(status) {
      if (!this.is_point) {
        await this.$api.courses
            .update_submission(this.user_attempts[0].id, {
              status: status,
              answer: this.selected_user.answer,
              instructor_comment: this.selected_user.instructor_comment,
            })
            .then(() => {
              this.getUserSubmissionsOnCreated(); // Просто обновляем данные с бэка
            });
      } else {
        this.selected_user.criteria_points.forEach((el) => {
          el.point = parseInt(el.point);
        });

        await this.$api.courses
            .update_submission(this.user_attempts[0].id, {
              status: status,
              answer: this.selected_user.answer,
              instructor_comment: this.selected_user.instructor_comment,
              criteria_points: this.selected_user.criteria_points,
            })
            .then(() => {
              this.getUserSubmissionsOnCreated(); // Просто обновляем данные с бэка
            });
      }
    }
    ,
    formatDate(date) {
      return moment(date).lang(this.currentLocale).format("DD MMMM YYYY");
    },
    async setSelectedUser(user) {
      console.log(user);
      this.selected_user = user;

      const selectedCard = document.querySelector(`#user_submission${user.id}`);

      console.log(selectedCard, this.selected_user, "sel2");
      if (selectedCard) {
        const cardOffsetTop = selectedCard.offsetTop;
        console.log(cardOffsetTop, "offset");
        this.offset = `${cardOffsetTop}px`;
        console.log("offset" + this.offset);
      }
      if (
        this.selected_user.criteria_points.length == 0 &&
        this.selected_user.criteria.length > 0
      ) {
        this.selected_user.criteria_points = this.selected_user.criteria.map(
          (obj) => ({
            criterion: obj.id,
            user_attempt: this.selected_user.id,
            point: "",
          })
        );
      }
      this.is_point = true;
      this.getUserInfo(user);
    },
    getUserInfo(user) {
      this.$api.courses.get_submission_by_id(user.id).then((res) => {
        this.user_attempts = res.data;
        const data = {
          resource_name: `${user.resource_name}`,
          question_text: `${user.question_text}`,
          submitted_time: `${this.formatDate(
            this.user_attempts[0].submitted_at
          )}`,
          answer: `${this.user_attempts[0].answer}`,
          submissions: this.user_attempts[0].submissions,
          status: this.user_attempts[0].status,
        };
        this.selected_user = { ...user, ...data };
      });
    },
    async getUserSubmissions() {
      if (this.filters.status === "all") {
        this.filters.status = "";
      }
      const { data } = await this.$api.courses.get_submissions({
        params: this.filters,
      });
      this.pagination.max_page = Math.ceil(
        data.count / this.pagination.per_page
      );
      this.user_submissions = data.results;
      for (let us of this.user_submissions) {
        if (us.criteria_points.length == 0 && us.criteria.length > 0) {
          us.criteria_points = us.criteria.map((obj) => ({
            criterion: obj.id,
            user_attempt: us.id,
            point: "",
          }));
        }
      }
      //comment later after back is done
      // this.user_submissions = this.user_submissions.map( el => ({...el, criteria:[{description:'test', weight:2, point:undefined}], threshold:1}))
      return data;
    },
  },
  created() {
    this.getUserSubmissionsOnCreated();
  },
  watch: {
    "filters.status": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.filters.page = 1;
      }
      this.getUserSubmissionsOnCreated();
    },
    "filters.page": function () {
      this.getUserSubmissionsOnCreated();
    },
    "filters.course": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.filters.page = 1;
        this.filters.group_number = undefined;
      }
      this.getUserSubmissionsOnCreated();
    },
    "filters.group_number": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.filters.page = 1;
      }
      this.getUserSubmissionsOnCreated();
    },
    "filters.search": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.filters.page = 1;
      }
      this.getUserSubmissionsOnCreated();
    },
    "selected_user.criteria_points": {
      handler: function () {
        this.is_point =
          this.selected_user.criteria.length > 0 &&
          this.selected_user.criteria_points.every(
            (p) =>
              p.point &&
              p.point <=
                this.selected_user.criteria.find((c) => c.id == p.criterion)
                  .weight
          );
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.scrollable {
  overflow-y: auto;
}
.student__grade input {
  width: 80px;
  border: 1px solid #d1d5db;
  box-shadow: 0px 1px 2px 0px #0000000d;
  border-radius: 6px;
}
</style>

<template>
  <div v-if="group" class="flex flex-col gap-10">
    <div class="flex gap-10">
<!--      <PercentageBox text="Средний балл по курсу:" :total="meanScore" />-->
      <PercentageBox text="Средний балл по курсу:"  :total="Number(this.statistics?.total_mean_percent || 0).toFixed(0)" />
      <PercentageBox text="Процент выполнения заданий (%):" :total="Number(this.statistics?.total_count_percent || 0).toFixed(0)" />
    </div>
    <div class="flex flex-col gap-4">
      <div class="statistics-box__title">Распределение оценок</div>
      <div class="flex-1">
        <GradesChart :statistics="statistics" />
      </div>
    </div>
  </div>
  <div v-else class="flex flex-col items-center">
    Выберите группу для отображения
  </div>
</template>

<script>
import { defineComponent } from "vue";
import PercentageBox from "@/views/courses/TaskPercentage/PercentageBox.vue";
// import BarChartWrapper from "@/views/courses/CourseEdit/Statistics/BarChartWrapper.vue";
import GradesChart from "@/views/courses/CourseEdit/Statistics/GradesChart.vue";
import PerformanceGradesMixin from "@/views/courses/CourseEdit/Statistics/PerformanceGradesMixin";

export default defineComponent({
  name: "PerformanceGrades",
  components: {
    GradesChart,
    PercentageBox,
    // BarChartWrapper,
  },
  props: ["group"],
  mixins: [PerformanceGradesMixin],
  data() {
    return {
      maxScore: 100,
      meanScore: 0,
      chartData: null,
      chartOptions: {
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            max: this.maxScore,
          },
        },
      },
    };
  },
  methods: {
    generateChartData() {
      if (!this.statistics) return;

      const gradeEntries = Object.entries(this.statistics)
          .filter(([key]) => key.includes("__grade"))
          .map(([key, value]) => {
            const parts = key.split("__");
            const category = parts[1];
            return { category, grade: value };
          });

      // Лейблы и значения
      const labels = gradeEntries.map((entry) => entry.category);
      const scores = gradeEntries.map((entry) => entry.grade);

      return {
        labels,
        datasets: [
          {
            label: "Средние оценки по категориям",
            data: scores,
            backgroundColor: "#4F46E5",
            borderColor: "#3730A3",
            borderWidth: 2,
            borderRadius: 8,
          },
        ],
      };
    },
    calculateTaskCompletionPercentage() {
      if (!this.statistics?.mean) return 0;

      const weightedPercentages = Object.entries(this.statistics.mean)
          .filter(([key]) => key.includes("weighted_percentage"))
          .map(([, value]) => value);

      const totalPercentage = weightedPercentages.reduce((sum, val) => sum + val, 0);

      return totalPercentage.toFixed(0); // Округление до целого
    }
  }
});
</script>

<style scoped>
.statistics-box__title {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  color: #111827;
}
</style>

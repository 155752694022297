import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import { GET_COURSE_GROUP, GET_COURSE } from "@/store/courses";
import TheTable from "../TheTable/TheTable.vue";
import TheCardHeader from "../TheCard/TheCardHeader.vue";
import {
  UserGroupIcon,
  UploadIcon,
  SearchIcon,
  MailIcon,
  PhoneIcon,
  DownloadIcon,
} from "@heroicons/vue/outline";
import TheButton from "@/components/Button/Button";
import InputText from "../InputText.vue";
import InputCheckbox from "@/components/InputCheckbox.vue";
import TheTableHead from "../TheTable/TheTableHead.vue";
import TheTableHeadCell from "../TheTable/TheTableHeadCell.vue";
import TheTableRow from "../TheTable/TheTableRow.vue";
import TheTableRowCell from "../TheTable/TheTableRowCell.vue";
import UserAvatar from "../UserAvatar.vue";
import TheTableBody from "../TheTable/TheTableBody.vue";
import TheCard from "@/components/TheCard/TheCard.vue";
import {
  GET_USERS,
  GET_COURSE_GROUPS,
  DELETE_USER_COURSE_GROUPS,
  CREATE_USER_COURSE_GROUPS,
  UPDATE_USER_COURSE_GROUPS,
  CREATE_USER_COURSE_GROUPS_FILE,
  GET_USERS_NEXT,
} from "@/store/users";
import moment from "moment";
import { DotsVerticalIcon, XIcon } from "@heroicons/vue/solid";
import TheModal from "../TheModal/TheModal.vue";
export default {
  components: {
    TheTable,
    TheCardHeader,
    TheButton,
    UserAvatar,
    UserGroupIcon,
    UploadIcon,
    SearchIcon,
    MailIcon,
    PhoneIcon,
    InputText,
    TheTableHead,
    TheTableHeadCell,
    TheTableRow,
    TheTableRowCell,
    TheTableBody,
    InputCheckbox,
    TheCard,
    DotsVerticalIcon,
    DownloadIcon,
    TheModal,
    XIcon,
  },
  props: {
    course_id: Number,
    group_number: String,
    group_id: Number,
    start_date: String,
    end_date: String,
    addMode: Boolean,
    search: {
      type: String,
      required: false,
    },
  },
  data: () => {
    return {
      students: [],
      tutors: [],
      superVisors: [],
      selected_role: "curator",
      usersToAdd: [],
      usersFile: null,
      search_new_users: "",
      nextLink: null,
      position: false,
      users_notif: false,
      fetching: false,
    };
  },
  computed: {
    ...mapFields(["courses.group", "courses.course"]),
    users() {
      if (this.selected_role === "curator") {
        return this.tutors;
      } else if (this.selected_role === "supervisor") {
        return this.superVisors;
      } else {
        return this.students;
      }
    },
    currentLocale() {
      return this.$i18n.locale;
    },
    selectAll: {
      get: function () {
        return this.users.every((r) => this.usersToAdd.includes(r.id));
      },
      set: function (value) {
        var selected = [];

        if (value) {
          this.users.forEach(function (user) {
            selected.push(user.id);
          });
        }

        this.usersToAdd = selected;
      },
    },
  },
  async created() {
    if (this.$route.name == "GroupForm") {
      await this.GET_COURSE(this.course_id);
    } else {
      await this.GET_COURSE_GROUP(this.group_id);
    }
    if (!this.addMode) {
      await this.getUsers("student", "").then((res) => {
        this.students = res.results;
      });
      this.students.map((el) => (el.is_dropdown_open = false));
      await this.getUsers("curator", "").then((res) => {
        this.tutors = res.results;
      });
      this.tutors.map((el) => (el.is_dropdown_open = false));

      await this.getUsers("supervisor", "").then((res) => {
        this.superVisors = res.results;
      })

      this.superVisors.map((el) => (el.is_dropdown_open = false));

    } else {
      await this.getNewUsers(this.selected_role, this.search_new_users).then(
        (res) => {
          this.tutors = res.results;
          this.tutors.map((el) => {
            if (this.usersToAdd.includes(el.id)) {
              el.selected = true;
            } else {
              el.selected = false;
            }
          });
        }
      );
      await this.getNewUsers(this.selected_role, this.search_new_users).then(
        (res) => {
          this.students = res.results;
          this.students.map((el) => {
            if (this.usersToAdd.includes(el.id)) {
              el.selected = true;
            } else {
              el.selected = false;
            }
          });
        }
      );
      await this.getNewUsers(this.selected_role, this.search_new_users).then(
          (res) => {
            this.superVisors = res.results;
            this.superVisors.map((el) => {
              if(this.superVisors.includes(el.id)) {
                el.selected = true;
              } else {
                el.selected = false;
              }
            })
          }
      )
    }
    // window.onscroll = () => {
    //   this.extendUsersList();
    // };
  },
  methods: {
    ...mapActions([GET_COURSE, GET_COURSE_GROUP, GET_USERS_NEXT]),
    checkIfNearEnd() {
      const sentinel = this.$refs.sentinel;
      if (sentinel) {
        if (sentinel instanceof HTMLElement) {
          const sentinelRect = sentinel.getBoundingClientRect();
          const isNearEnd = sentinelRect.top <= window.innerHeight;

          if (isNearEnd) {
            this.extendUsersList();
          }
        } else {
          console.error("Sentinel is not an HTMLElement", sentinel);
        }
      } else {
        console.error("Sentinel ref is not defined");
      }
    },

    formatDate(date) {
      moment.locale(this.currentLocale);
      return moment(date).format("DD.MM.YYYY");
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async getNewUsers(role) {
      var response = await this.$store.dispatch(GET_USERS, {
        course: this.course_id,
        roles: [role],
        group_number: this.group_id,
        search: this.search_new_users,
        limit: 15,
      });
      this.nextLink = response.next;
      return response;
    },
    async getUsers(role, search) {
      var response = await this.$store.dispatch(GET_COURSE_GROUPS, {
        course: this.course_id,
        role: role,
        group_number: this.group_id,
        search: search,
        page_size: 100,
      });
      this.nextLink = response.next;
      return response;
    },
    uploadFile(e) {
      var file = e.target.files[0];

      this.$store.dispatch(CREATE_USER_COURSE_GROUPS_FILE, {
        course: this.course_id,
        group_number: this.group_number,
        file: file,
      });
      e.target.value = "";
      this.$router.push({
        name: "GroupView",
        params: { id: this.course_id },
        query: { group_number: this.group_number },
      });
    },
    async deleteUser(user) {
      if (this.selected_role === "curator") {
        this.tutors = this.tutors.filter((obj) => obj.id != user.id);
      } else {
        this.students = this.students.filter((obj) => obj.id != user.id);
      }
      return await this.$store.dispatch(DELETE_USER_COURSE_GROUPS, user.id);
    },
    async addNewUsers() {
      if (this.selected_role === "curator") {
        this.tutors = this.tutors.filter(
          (obj) => !this.usersToAdd.includes(obj.id)
        );
      } else if(this.selected_role === "student") {
        this.students = this.students.filter(
          (obj) => !this.usersToAdd.includes(obj.id)
        );
      } else {
        this.superVisors = this.superVisors.filter(
          (obj) => !this.usersToAdd.includes(obj.id)
        );
      }
      if (this.usersToAdd.length == 0) {
        this.users_notif = true;
        return;
      }
      let res = '';
      if(this.group_id){
        res = await this.$store.dispatch(UPDATE_USER_COURSE_GROUPS, {
          group_id: this.group_id,
          add_tutor_ids: this.usersToAdd,
          add_student_ids: this.usersToAdd,
          add_supervisor_ids: this.usersToAdd,

        });
      }else{
        res = await this.$store.dispatch(CREATE_USER_COURSE_GROUPS, {
          course: this.course_id,
          group_number: this.group_number,
          users: this.usersToAdd,
          start_date: this.start_date,
          end_date: this.end_date,
        });
      }
      if (this.$router.currentRoute._value.name == "GroupView") {
        this.$router.go();
      } else {
        this.$router.push({
          name: "GroupView",
          query: { group_number: res[0].course_group },
        });
      }
    },
    async extendUsersList() {
      // const nearToEnd =
      //   window.innerHeight + window.pageYOffset >=
      //   document.body.offsetHeight - 10; // Adjust threshold as necessary
      const notFetching = !this.fetching;
      const hasMoreData = this.nextLink;

      if (notFetching && hasMoreData) {
        this.fetching = true; // Lock to prevent duplicate calls
        const result = await this.GET_USERS_NEXT(
          this.nextLink.split("/users")[1]
        );
        if (this.selected_role === "student") {
          this.students = [...this.students, ...result.results];
          if (!this.addMode) {
            this.students.forEach((el) => (el.is_dropdown_open = false));
          }
        } else {
          this.tutors = [...this.tutors, ...result.results];
          if (!this.addMode) {
            this.tutors.forEach((el) => (el.is_dropdown_open = false));
          }
        }
        this.nextLink = result.next;
        this.fetching = false;
      }
    },
    addAllToNewUsers(event) {
      let checkboxes = document.getElementsByClassName("checkbox");
      checkboxes = Array.prototype.slice.call(checkboxes);
      var ev = new Event("change");
      if (event.target.checked) {
        checkboxes.map((ch) => {
          ch.checked = true;
          ch.dispatchEvent(ev);
        });
      } else {
        checkboxes.map((ch) => {
          ch.checked = false;
          ch.dispatchEvent(ev);
        });
      }
    },
    addToNewUsers(event, id) {
      if (event.target.checked) {
        if (!this.usersToAdd.includes(id)) {
          this.usersToAdd.push(id);
        }
      } else {
        this.usersToAdd = this.usersToAdd.filter((el) => el !== id);
      }
    },
  },
  watch: {
    search: async function () {
      if (this.selected_role === "curator") {
        await this.getUsers("curator", this.search).then((res) => {
          this.tutors = res.results;
        });
        this.tutors.map((el) => (el.is_dropdown_open = false));
      } else if(this.selected_role === "student") {
        await this.getUsers("student", this.search).then((res) => {
          this.students = res.results;
        });
        this.students.map((el) => (el.is_dropdown_open = false));
      } else {
        await this.getUsers("supervisor", this.search).then((res) => {
          this.superVisors = res.results;
        })
        this.superVisors.map((el) => (el.is_dropdown_open = false));
      }
    },
    search_new_users: async function () {
      if (this.selected_role === "curator") {
        await this.getNewUsers(this.selected_role, this.search_new_users).then(
          (res) => {
            this.tutors = res.results;
            this.tutors.map((el) => {
              if (this.usersToAdd.includes(el.id)) {
                el.selected = true;
              } else {
                el.selected = false;
              }
            });
          }
        );
      } else if (this.selected_role === "student") {
        await this.getNewUsers(this.selected_role, this.search_new_users).then(
          (res) => {
            this.students = res.results;
            this.students.map((el) => {
              if (this.usersToAdd.includes(el.id)) {
                el.selected = true;
              } else {
                el.selected = false;
              }
            });
          }
        );
      }else {
        await this.getNewUsers(this.selected_role, this.search_new_users).then(
          (res) => {
            this.superVisors = res.results;
            this.superVisors.map((el) => {
              if(this.usersToAdd.includes(el.id)) {
                el.selected = true;
              } else {
                el.selected = false;
              }
            })
          }
        )
      }
      this.usersToAdd.forEach(
        (el) => (this.users.find((e) => e.id == el).selected = true)
      );
    },
    selected_role: async function () {
      if (!this.addMode) {
        if (this.selected_role === "curator") {
          await this.getUsers("curator", this.search).then((res) => {
            this.tutors = res.results;
          });
          this.tutors.map((el) => (el.is_dropdown_open = false));
        } else if (this.selected_role === "student") {
          await this.getUsers("student", this.search).then((res) => {
            this.students = res.results;
          });
          this.students.map((el) => (el.is_dropdown_open = false));
        }else {
            await this.getUsers("supervisor", this.search).then((res) => {
                this.superVisors = res.results;
            })
            this.superVisors.map((el) => (el.is_dropdown_open = false));
        }
      } else {
        if (this.selected_role === "curator") {
          await this.getNewUsers(
            this.selected_role,
            this.search_new_users
          ).then((res) => {
            this.tutors = res.results;
            this.tutors.map((el) => {
              if (this.usersToAdd.includes(el.id)) {
                el.selected = true;
              } else {
                el.selected = false;
              }
            });
          });
        } else if (this.selected_role === "student") {
          await this.getNewUsers(
            this.selected_role,
            this.search_new_users
          ).then((res) => {
            this.students = res.results;
            this.students.map((el) => {
              if (this.usersToAdd.includes(el.id)) {
                el.selected = true;
              } else {
                el.selected = false;
              }
            });
          });
        } else {
            await this.getNewUsers(
                this.selected_role,
                this.search_new_users
            ).then((res) => {
                this.superVisors = res.results;
                this.superVisors.map((el) => {
                if(this.usersToAdd.includes(el.id)) {
                    el.selected = true;
                } else {
                    el.selected = false;
                }
                })
            })
        }
      }
      this.usersToAdd.forEach(
        (el) => (this.users.find((e) => e.id == el).selected = true)
      );
    },
    addMode: async function () {
      this.search_new_users = "";
      this.usersToAdd = [];
      if (!this.addMode) {
        if (this.selected_role === "curator") {
          await this.getUsers("curator", this.search).then((res) => {
            this.tutors = res.results;
          });
          await this.tutors.map((el) => (el.is_dropdown_open = false));
        } else if (this.selected_role === "student") {
          this.getUsers("student", this.search).then((res) => {
            this.students = res.results;
          });
          this.students.map((el) => (el.is_dropdown_open = false));
        } else {
            this.getUsers("supervisor", this.search).then((res) => {
                this.superVisors = res.results;
            })
            this.superVisors.map((el) => (el.is_dropdown_open = false));
        }
      } else {
        if (this.selected_role === "curator") {
          await this.getNewUsers(
            this.selected_role,
            this.search_new_users
          ).then((res) => {
            this.tutors = res.results;
            this.tutors.map((el) => {
              if (this.usersToAdd.includes(el.id)) {
                el.selected = true;
              } else {
                el.selected = false;
              }
            });
          });
        } else if (this.selected_role === "student") {
          await this.getNewUsers(
            this.selected_role,
            this.search_new_users
          ).then((res) => {
            this.students = res.results;
            this.students.map((el) => {
              if (this.usersToAdd.includes(el.id)) {
                el.selected = true;
              } else {
                el.selected = false;
              }
            });
          });
        } else {
            await this.getNewUsers(
                this.selected_role,
                this.search_new_users
            ).then((res) => {
                this.superVisors = res.results;
                this.superVisors.map((el) => {
                if(this.usersToAdd.includes(el.id)) {
                    el.selected = true;
                } else {
                    el.selected = false;
                }
                })
            })
        }
      }
    },
  },
};

<template>
  <div>
    <canvas ref="chartCanvas"></canvas>
  </div>
</template>

<script>
import { Chart, BarElement, CategoryScale, LinearScale } from "chart.js";
import { ref, onMounted, watch } from "vue";

Chart.register(BarElement, CategoryScale, LinearScale);

export default {
  props: {
    statistics: Object,
  },
  setup(props) {
    const chartCanvas = ref(null);
    let chartInstance = null;

    const renderChart = () => {
      if (!props.statistics?.mean) return;

      const gradesEntries = Object.entries(props.statistics.mean)
          .filter(([key]) => key.endsWith("__grade_percent"));

      const labels = gradesEntries.map(([key]) => key.split("__")[1]);
      // eslint-disable-next-line no-unused-vars
      const dataValues = gradesEntries.map(([_, value]) => value);

      const backgroundColors = dataValues.map(value => {
        if (value > 70) return "#ECFDF5";
        if (value >= 40) return "#FFEADC";
        return "#FEF2F2";
      });

      const borderColors = dataValues.map(value => {
        if (value > 70) return "#059669";
        if (value >= 40) return "#F97316";
        return "#DC2626";
      });

      if (chartInstance) {
        chartInstance.destroy();
      }

      const ctx = chartCanvas.value.getContext("2d");
      chartInstance = new Chart(ctx, {
        type: "bar",
        data: {
          labels,
          datasets: [
            {
              label: "Средний процент оценок по категориям",
              data: dataValues,
              backgroundColor: backgroundColors,
              borderColor: borderColors,
              borderWidth: 2,
              borderRadius: 20,
            },
          ],
        },
        options: {
          responsive: true,
          scales: {
            x: { ticks: { font: { size: 16 } } },
            y: {
              beginAtZero: true,
              max: 100, // Оценки по 10-балльной шкале
              ticks: { font: { size: 16 } },
            },
          },
          plugins: {
            legend: { display: false },
            title: { display: false },
          },
        },
      });
    };



    onMounted(renderChart);
    watch(() => props.statistics, renderChart, { deep: true });

    return { chartCanvas };
  },
};
</script>
